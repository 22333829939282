<template>
  <div id="FilterBar" class="w-full text-white bg-gray">
    <div
      v-if="!loading"
      class="flex flex-row flex-wrap items-center justify-evenly m-2"
    >
      <div class="text-2xl pr-2 border-r">
        <h3>Filter Search</h3>
      </div>
      <div class="flex flex-row items-center">
        <div class="flex flex-col items-center">
          <!-- <div class="flex flex-row flex-wrap items-center"> -->
            <div class="grid place-content-evenly auto-cols-min gap-0 grid-cols-5 items-left text-center">
            <div
              class="mx-0 px-1 my-1"
              v-for="type in types"
              :key="type.entityTypeId"
            >
              <input
                @change="sendSelection(selection)"
                type="radio"
                class="opacity-0 w-0"
                :id="type.entityTypeId"
                checked
                v-model="selection.type"
                :value="type.entityTypeId"
              />
              <label
                class="bg-gray-dark pr-2 px-1 border rounded hover:bg-green hover:text-white"
                :for="type.entityTypeId"
                :class="selection.type == type.entityTypeId ? 'bg-green' : ''"
              >
                {{ type.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="flex flex-row flex-wrap items-center text-center text-gray">
        <Datepicker
          value-type="format"
          range 
          :format="'MM-DD-YYYY'"
          @close="sendSelection(selection)"
          @clear="sendSelection(selection)"
          v-model="date"
          placeholder="Pick a Date" />
      </div>

      <div v-if="showSearch" flex flex-col flex-wrap>
        <div>
          <input class="text-gray rounded-xl ml-8 -mt-1" type="text" :placeholder="selection.search.type == 'objKey' ? 'Enter ObjKey' : 'Enter filename'" @input="sendSelection(selection)" v-model="selection.search.terms" />
        </div>
        <div class="-pb-2 -mb-2">
          Search By: 
          <input
                @change="sendSelection(selection)"
                type="radio"
                class="opacity-0 w-0"
                id="objKey"
                checked
                v-model="selection.search.type"
                value="objKey"
              />
              <label
                class="inline-block bg-gray-dark px-2 my-1 mx-1 border rounded hover:bg-green hover:text-white"
                for="objKey"
                :class="selection.search.type == 'objKey' ? 'bg-green' : ''"
              >
                Object Key
              </label>
              <input
                @change="sendSelection(selection)"
                type="radio"
                class="opacity-0 w-0"
                id="filename"
                v-model="selection.search.type"
                value="filename"
              />
              <label
                class="inline-block bg-gray-dark px-2 mx-1 border rounded hover:bg-green hover:text-white"
                for="filename"
                :class="selection.search.type == 'filename' ? 'bg-green' : ''"
              >
                File Name
              </label>
              <input
                @change="sendSelection(selection)"
                type="radio"
                class="opacity-0 w-0"
                id="pendingOnly"
                v-model="selection.search.type"
                value="pending"
              />
              <label
                class="inline-block bg-gray-dark px-2 mx-1 border rounded hover:bg-green hover:text-white"
                for="pendingOnly"
                :class="selection.search.type == 'pendingOnly' ? 'bg-green' : ''"
              >
                Pending
              </label>
        </div>
      </div>
    </div>
    <div v-if="loading" class="flex flex-col flex-wrap items-center">
      <div></div>
      <div class="pb-2">
        <half-circle-spinner
          :animation-duration="1000"
          :size="40"
          color="white"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { HalfCircleSpinner } from "epic-spinners";

export default {
  name: "FilterBar",
  components: { Datepicker, HalfCircleSpinner },
  props: ["types", "loading", "showSearch"],
  data() {
    return {
      selection: {
        type: "",
        startDate: "",
        endDate:"",
        search: {
          type: "objKey",
          terms: "",
        }
      },
      date:"",
    };
  },
  methods: {
    sendSelection(query) {
      // console.log("Query: ", query);
      if (query) {
        if (this.date) {
          this.selection.startDate = this.date[0];
          this.selection.endDate  = this.date[1];
          console.log(this.selection);
        }
        if (query.type) {
          this.$emit("search-entries", query);
        }
      }
    },
  },
};
</script>