import Vue from 'vue'
import App from './App.vue'
import Router from './router';
import './assets/styles/index.css';
import axios from 'axios';
import { onAppSingleSignOn } from "@/auth";

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

onAppSingleSignOn();
// console.log(response);
// // onAppSingleSignOn();

new Vue({
  router: Router,
  render: h => h(App),
}).$mount('#app')
