import * as msal from "@azure/msal-browser";

const msalConfig = {
  auth: {
    clientId: process.env.VUE_APP_AAD_CLIENTID || "",
    authority: process.env.VUE_APP_AAD_AUTHORITY || "",
    redirectUri: window.location.origin,
    // This takes out a MSAL call, improves performance
    cloudDiscoveryMetadata:
      '{"tenant_discovery_endpoint":"https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration","api-version":"1.1","metadata":[{"preferred_network":"login.microsoftonline.com","preferred_cache":"login.windows.net","aliases":["login.microsoftonline.com","login.windows.net","login.microsoft.com","sts.windows.net"]},{"preferred_network":"login.partner.microsoftonline.cn","preferred_cache":"login.partner.microsoftonline.cn","aliases":["login.partner.microsoftonline.cn","login.chinacloudapi.cn"]},{"preferred_network":"login.microsoftonline.de","preferred_cache":"login.microsoftonline.de","aliases":["login.microsoftonline.de"]},{"preferred_network":"login.microsoftonline.us","preferred_cache":"login.microsoftonline.us","aliases":["login.microsoftonline.us","login.usgovcloudapi.net"]},{"preferred_network":"login-us.microsoftonline.com","preferred_cache":"login-us.microsoftonline.com","aliases":["login-us.microsoftonline.com"]}]}'
  },
  cache: {
    cacheLocation: "sessionStorage"
  }
};
export const msalInstance = new msal.PublicClientApplication(msalConfig);

const scopes = ["openid", "profile"];

export const onAppSingleSignOn = async () => {
  const tokenResponse = await acquireToken();
  return tokenResponse;
  // await store.commit("setLoginResponse", tokenResponse);
};

export const acquireToken = async () => {
  if (msalInstance.getAllAccounts() && msalInstance.getAllAccounts()[0]) {
    const request = {
      scopes,
      forceRefresh: false,
      account: msalInstance.getAllAccounts()[0]
    };
    return await msalInstance.acquireTokenSilent(request).catch(async error => {
      if (error instanceof msal.InteractionRequiredAuthError) {
        // fallback to interaction when silent call fails
        return await msalInstance.acquireTokenPopup(request).catch(error => {
          handleError(error);
        });
      }
    });
  }
};

const handleError = (error) => {
  console.error(error);
  login();
};

export const login = async () => {
  const response = await msalInstance.loginPopup({ scopes });
  // store.commit("setLoginResponse", response);
  return response;
};

export const logout = async () => {
  await msalInstance.logout();
  // store.commit("setLoginResponse", null);
};
