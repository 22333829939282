<template>
    <div id="Refresh">
        <div class="fixed top-40 right-0 p-2 min-h-auto">
            <button @click="sendSelection(query)" class="bg-green opacity-70 hover:opacity-100 text-white py-1 px-2 rounded text-3xl">&#x27F3;</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Refresh',
        props: [ 'query' ],
        methods: {
            sendSelection(query) {
                this.$emit('search-entries', query);
            }
        },

    }
</script>