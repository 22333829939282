import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboard from "../components/Dashboard.vue"
import Transactions from "../components/Transactions.vue"

Vue.use(VueRouter)

export default new VueRouter({
    routes: [
        {
            path: '/',
            redirect: {
                name: "dashboard"
            },
        },
        {
            path: "/dashboard",
            name: "dashboard",
            component: Dashboard
        },
        {
            path: "/transactions",
            name: "transactions",
            component: Transactions
        }
    ]
})