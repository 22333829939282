<template>
    <div id="Header" class="w-full text-3xl bg-gray-dark text-center text-white py-4 px-4">
        <div class="flex flex-col flex-wrap">
            <div class="flex-1 -mb-2">
                <img class="inline pr-2" width="50px" src="../assets/images/BccLogo.2.png" />
                <span class="flex-2">B2B Tracker</span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Header'
    }
</script>