<template>
    <div id="Transactions" >
        <div class="w-auto bg-white border border-gray-light rounded-2xl shadow-inner shadow-lg p-6 py-2 m-8 hover:shadow-xl hover:shadow-xl" v-for="transaction in filteredEntries" :key="transaction.index">
            <div class="w-auto text-xl">
                <div class="flex text-lg flex-row  divide-x">
                    <div class="items-center font-bold px-3 text-green">{{transaction.name}} </div> 
                    <div class="items-center font-bold px-3"> Opened: {{transaction.openTimestamp}} </div> 
                    <div class="items-center font-bold px-3"  :class="transaction.closeTimestamp == null ? 'w-auto text-xl text-red' : 'w-auto text-xl'">{{ transaction.closeTimestamp ==null ? 'Pending ':'Closed: '+transaction.closeTimestamp}} </div> 
                    <div class="items-center font-bold px-3" > </div> 
                    <div class="items-right font-bold px-3">{{ transaction.objKey ==null ? '':' ObjKey: '+transaction.objKey}} </div> 
                </div>
            </div>
            <table class="w-full table-fixed rounded-2xl border border-gray-light self-center text-md my-4">
                <thead>
                    <tr nowrap class="border border-gray-light bg-green-light text-green text-lg font-bold">
                        <th class="w-1/12 px-2">Flow</th>
                        <th class="w-2/3 px-2 text-left">FileName</th>
                        <th class="w-1/12 px-2">DocType</th>
                        <th class="w-1/12 px-2">Step</th>
                        <th class="w-1/4 px-2">Created</th>
                    </tr>
                </thead>
                <tbody class="text-gray text-md bg-white">
                    <tr class="border border-gray-light px-3 py-2 divide-x" v-for="entry in transaction.details" :key="entry.index">
                        <td class="text-right px-3">{{ entry.OutboundFlow == 0 ? "in &#8594;\t" : "&#8592; out\t" }}</td>
                        <td class="px-2 overflow-elipsis">{{ entry.fileName || "---"}}</td>
                        <td class="text-center px-2">{{ entry.docType || "---"}}</td>
                        <td class="text-center px-3">{{ entry.step || "---"}}</td>
                        <td class="text-center px-2">{{ entry.createTimestamp || "---"}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="loading" class="flex flex-col flex-wrap items-center"> 
            <div>
            </div>
            <div class="pb-2">
                <half-circle-spinner
                :animation-duration="1000"
                :size="40"
                color="white"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners';

export default {
    name: 'Transactions',
    components: { HalfCircleSpinner },
    props: ['filteredEntries', 'loading', 'query'], 
    }
</script>