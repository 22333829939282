<template>
  <div id="Dashboard">
    <div class="flex flex-col">
      <div class="flex-1 mx-7 mt-10">
        <p class="mt-10 text-center font-bold text-green text-2xl" v-if="Object.keys(filteredEntries).length <= 0">No Results Found</p>
        <Transactions v-if="filteredEntries" :filteredEntries="filteredEntries"/>
      </div>

    </div>
  </div>
</template>

<script>
import Transactions from './Transactions.vue'

export default {
  name: 'Dashboard',
  components: { Transactions },
  props: ['filteredEntries'],
}
</script>