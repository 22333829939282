<template>
    <div id="app" class="bg-gray-lightest text-gray">
        <div class="w-full fixed flex flex-col items-center">
            <Header />
            <div class="bg-none text-right px-5 py-2 -mt-14 text-xl text-white w-full">
                <router-link v-if="!authenticated" to="/dashboard" class="hover:bg-gray-light hover:bg-opacity-40 border border-gray-light rounded" v-on:click.native="login()" replace>
                        <svg class="inline m-1 pb-1" xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
                            <title>Login with Microsoft</title>
                            <rect x="1" y="1" width="9" height="9" fill="#f25022"/>
                            <rect x="1" y="11" width="9" height="9" fill="#00a4ef"/>
                            <rect x="11" y="1" width="9" height="9" fill="#7fba00"/>
                            <rect x="11" y="11" width="9" height="9" fill="#ffb900"/>
                        </svg>
                        <span class="text-base mr-1">Sign in with Microsoft</span>
                </router-link>
                <div class="-mt-4 -ml-4 text-left">
                    <router-link class="text-sm px-2 py-1 hover:opacity-100 bg-red opacity-50 rounded" v-if="authenticated" to="/dashboard" v-on:click.native="logout()" replace>Logout</router-link>
                </div>
                <div v-if="authenticated" w-auto class=" -mt-2 text-xl mb-4"> 
                    {{ user }}
                </div>
            </div>
            <FilterBar v-if="authenticated" class=" -mt-2 rounded-b-2xl" :showSearch="showSearch" :loading="groupsLoading" :types="types" @search-entries="searchEntries" />
        </div>
        <Refresh  @search-entries="searchEntries" :query="query" v-if="authenticated && Object.keys(filteredEntries).length > 0" />
        <div class="flex flex-col flex-wrap items-center">
            <div>
            </div>
            <div v-if="authenticated && entriesLoading" class="pt-48">
                    <half-circle-spinner
                    :animation-duration="1000"
                    :size="55"
                    color="#474747"
                    />
                </div>
        </div>
            <router-view v-if="!entriesLoading" class="pt-24 col-start-1 col-span-6" :filteredEntries="filteredEntries" />
    </div>
</template>

<script>
import axiosConfig from './axiosConfig.js'
import Header from './components/Header.vue'
import Refresh from './components/Refresh.vue'
import { logout, login } from '@/auth'
import FilterBar from './components/FilterBar.vue'
const typeURL = '/backend/query/trackertype'
const baseURL = process.env.VUE_APP_DATA_URL
import { HalfCircleSpinner } from 'epic-spinners';

export default {
  name: 'App',
  components: { Header, FilterBar, Refresh, HalfCircleSpinner },
  data() {
      return {
        "filteredEntries": [],
        "types": [],
        "authenticated": false,
        "query": {},
        "groupsLoading": true,
        "entriesLoading": false,
        "user": "",
        "searchTerms": "",
        "roles": [],
        "showSearch": false
      }
    },
    mounted() {
        if(this.authenticated) {
            this.fetchTypes();
        }
    },
    methods: {
        async login() {
            const response = await login();
            this.user = response.account.username
            this.roles = response.account.idTokenClaims.roles
            this.roles = this.roles.map(role => {
                return  role.toLowerCase()
            })
            console.log('roles '+this.roles) 
            if(response) {
                this.authenticated = true;
                this.fetchTypes();
                this.$router.replace({ name: "dashboard" }, () => {});
            }
        },
        logout() {
            logout();
            this.authenticated = false;
        },
        setAuthenticated(status) {
            this.authenticated = status;
        },
        getAuthenticated() {
            return this.authenticated;
        },
        async fetchTypes() {
            this.groupsLoading = true;
            await axiosConfig.get(baseURL+typeURL)
                .then(result => {
                    // these are valid roles   ['admin','bcts','cashmgmt','hr','invoices','payroll']
                    //this.roles = ['invoices'] //for testing roles
                    result.data.objectList.forEach(type => { 
                        //console.log( this.user+' \t'+ type.secGroup+' \t'+ type.name)
                        if(this.roles.includes("admin")||this.roles.includes(type.secGroup.toLowerCase()) ) {
                            if (this.roles.length>1||this.roles.includes("admin")) {
                                // type.name = type.secGroup.charAt(0).toUpperCase()+type.secGroup.slice(1)+'-'+type.name
                                type.name = type.secGroup.charAt(0).toUpperCase()+type.secGroup.slice(1)+'-'+type.name
                            }
                            this.types.push(type);
                        }
                        if (type.secGroup.toLowerCase().includes('admin')) {
                            this.showSearch = true;
                        }
                    });
                    this.types.sort(compare)
                    //console.log(this.types)
                    this.groupsLoading = false;
                });
        },
        async searchEntries(search) {
            this.query = search;
            this.entriesLoading = true;
            let entries = "";
            if(search.search && search.search.terms != "") {
                if(search.search.type && search.search.type == "objKey") {
                    entries = await axiosConfig.get(`${baseURL}/backend/query/search/objkey/${search.search.terms}`)
                    .then(result => {
                        return result.data.objectList;
                    });
                } else if(search.search.type && search.search.type == "filename") {
                    entries = await axiosConfig.get(`${baseURL}/backend/query/search/filename/${search.search.terms}`)
                    .then(result =>{
                        return result.data.objectList;
                    });
                }
                if(search.date && (search.date != null || search.date != "") && entries.length > 0) {
                    let date = search.date.split('-')
                    let searchDate = date[0] + '/' + date[1] + '/' + date[2];
                    let filtered = entries.filter(entry =>
                        entry.openTimestamp.includes(searchDate)
                    );
                    entries = filtered;
                } 
            } else if(!search.startDate || search.startDate == "") {
                entries = await axiosConfig.get(`${baseURL}/backend/query/search/latest/${search.type}`)
                .then(result => {
                    return result.data.objectList;
                });
            } else {
                entries = await axiosConfig.get(`${baseURL}/backend/query/search/daterange/${search.type}:${search.startDate}:${search.endDate}`)
                .then(result => {
                    return result.data.objectList;
                });
            }

            // console.log(entries)
            //adjust indexing here
            if(entries.length > 0) {
                this.filteredEntries = entries.reduce((r,a) => {
                    r[a.headId] = r[a.headId] || {};
                    r[a.headId].groupId = a.groupId;
                    r[a.headId].closeTimestamp = a.closeTimestamp;
                    r[a.headId].openTimestamp = a.openTimestamp;
                    r[a.headId].objKey = a.objKey;
                    r[a.headId].headId = a.headId;
                    r[a.headId].name = a.name;
                    r[a.headId].details = r[a.headId].details || [];
                    r[a.headId].details.push(a);
                    return r;
                }, {});
            } else {
                this.filteredEntries = {};
            }
            // console.log("FilteredEntries: ", this.filteredEntries);
            this.entriesLoading = false;
        },
      }
}

function compare(a, b) {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}
</script>