import { acquireToken, login } from "@/auth";
import axios from "axios";

const axiosApi = axios.create({});

// This is handled by the vue cli dev proxy locally
// if (process.env.NODE_ENV !== "development") {
axiosApi.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
// }

axiosApi.interceptors.request.use(async config => {
  let tokenResponse = await acquireToken();
  // console.log(tokenResponse);
  if (tokenResponse) {
    config.headers.Authorization = `Bearer ${tokenResponse.idToken}`;
  } else {
    tokenResponse = await login();
    config.headers.Authorization = `Bearer ${tokenResponse.idToken}`;
  }
  return config;
});

export default axiosApi;
